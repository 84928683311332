<div class="space-y-4">

  <div class="text-center break-all">
    {{ item.name }}
  </div>

  <ul class="grid md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-1">

    <ng-template ngFor [ngForOf]="packages" let-package>
      <li class="flex items-center justify-between px-2 py-1 rounded bg-gray-100">
        <app-tracking-number-link class="font-mono" [value]="package.trackingNumber"></app-tracking-number-link>
        <button class="branded-alt button-sm" data-tippy (click)="copyToClipboard(package.trackingNumber)">
          <fa-icon icon="copy"></fa-icon>
        </button>
      </li>
    </ng-template>

  </ul>

</div>
