import { Component, OnInit, Input } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { deburr, some } from 'lodash-es';

import { QueueService } from '@services/queue.service';
import { AccessPointService } from '@services/access-point.service';
import { AccessPoint, AccessPointPackage, AccessPointHistory } from '@models/access-point';

@Component({
  selector: 'app-access-point-card',
  templateUrl: './access-point-card.component.html',
  styleUrls: ['./access-point-card.component.scss']
})
export class AccessPointCardComponent implements OnInit {

  @Input() item!: AccessPoint;
  @Input() isQueued = false;
  @Input() filterWord: string|null = null;

  highlightPickups = false;
  highlightDropoffs = false;

  historyLoading = false;

  constructor(
    private modalService: NgxSmartModalService,
    private accessPointService: AccessPointService,
    public queueService: QueueService
  ) { }

  ngOnInit(): void {
    if (this.filterWord) {
      const whereTrackingNumberIncludesFilterWord = (accessPointPackage: AccessPointPackage): boolean => {
        const normalizedFilterWord = deburr(this.filterWord!).toLowerCase();
        return accessPointPackage.trackingNumber.toLowerCase().includes(normalizedFilterWord);
      }
      this.highlightPickups = some(this.item.packagesToPickup, whereTrackingNumberIncludesFilterWord);
      this.highlightDropoffs = some(this.item.packagesToDropoff, whereTrackingNumberIncludesFilterWord);
    }
  }

  showPickupTrackingNumbersModal() {
    this.modalService.resetModalData('pickupTrackingNumbersModal');
    setTimeout(() => {
      this.modalService.setModalData(this.item, 'pickupTrackingNumbersModal');
      this.modalService.getModal('pickupTrackingNumbersModal').open();
    });
  }

  showDropoffTrackingNumbersModal() {
    this.modalService.resetModalData('dropoffTrackingNumbersModal');
    setTimeout(() => {
      this.modalService.setModalData(this.item, 'dropoffTrackingNumbersModal');
      this.modalService.getModal('dropoffTrackingNumbersModal').open();
    });
  }

  showHistory() {
    this.historyLoading = true;
    this.modalService.resetModalData('accessPointHistoryModal');
    this.accessPointService.getHistory(this.item.id).subscribe((history: AccessPointHistory) => {
      this.item.history = history;
      this.modalService.setModalData(this.item, 'accessPointHistoryModal');
      this.modalService.getModal('accessPointHistoryModal').open();
      this.historyLoading = false;
    });
  }

}
