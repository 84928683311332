<header class="sticky top-0 bg-primary">
  <div class="container mx-auto p-2 flex justify-between">

    <div class="flex gap-2 sm:gap-4 items-center">
      <img class="h-12" src="/assets/wol-logo.svg">
      <fa-icon size="2x" [icon]="['fab', 'ups']"></fa-icon>
      Dispatcher Frontend
    </div>

    <div class="flex gap-4 items-center">

      <button class="sm:flex gap-2" (click)="load()">
        <fa-icon icon="rotate"></fa-icon>
        <span class="hidden sm:block">{{ 'Refresh' | translate }}</span>
      </button>

      <button class="sm:flex gap-2" (click)="authService.logout()">
        <fa-icon icon="right-from-bracket"></fa-icon>
        <span class="hidden sm:block">{{ 'Logout' | translate }}</span>
      </button>

    </div>

  </div>
</header>

<section class="sticky top-1 bg-white shadow">
  <div class="container mx-auto p-2">
    <ng-container *ngIf="!isLoading; else loading">
      <div class="flex flex-col sm:flex-row gap-x-4 items-center">

        <div>
          {{ 'DriverNumberFor' | translate }}
          <span class="whitespace-nowrap">{{ date | date:'d MMMM y' }}</span>:
        </div>

        <div class="tracking-widest text-green-600 text-xl font-medium font-mono sm:leading-5">
          {{ driverNumber }}
        </div>

        <ngx-barcode6 [bc-value]="driverNumber" [bc-height]="48" [bc-margin]="0"
            [bc-margin-top]="0" [bc-margin-left]="0" [bc-margin-bottom]="0" [bc-margin-right]="0">
        </ngx-barcode6>

      </div>
    </ng-container>
  </div>
</section>

<div class="container mx-auto px-2 py-8">

  <main class="space-y-8" *ngIf="!isLoading; else loading">

    <div class="flex justify-end">
      <app-loader-button classes="branded" [loading]="isDownloadingXls" (action)="downloadXls()">
        Download XLS
      </app-loader-button>
    </div>

    <app-queue class="block"></app-queue>

    <div class="md:grid xl:grid-cols-3 2xl:grid-cols-2 gap-4">
      <div class="xl:col-span-2 2xl:col-auto">

        <section class="mb-1 md:mb-2 flex gap-2 md:gap-4">
          <input class="branded w-full md:text-lg"
              [placeholder]="(searchTrackingNumbers ? 'FilterPlaceholder' : 'AddressFilterPlaceholder') | translate"
              [(ngModel)]="filterWord" (ngModelChange)="filter()"
              autofocus>
          <button class="branded py-0" (click)="filter('')" *ngIf="filterWord">
            <fa-icon icon="xmark"></fa-icon>
          </button>
        </section>

        <div class="flex justify-between">
          <div>
            {{ 'Results' | translate: { count: filteredAccessPoints.length } }}
          </div>
          <div class="md:flex gap-4">
            <div>
              <input id="searchTrackingNumbers" type="checkbox" [(ngModel)]="searchTrackingNumbers" (ngModelChange)="filter()">
              <label for="searchTrackingNumbers">
                {{ 'SearchTrackingNumbers' | translate }}
              </label>
            </div>
            <div>
              <input id="hasPickups" type="checkbox" [(ngModel)]="hasPickups" (ngModelChange)="filter()">
              <label for="hasPickups">
                {{ 'HasPickups' | translate }}
              </label>
            </div>
            <div>
              <input id="hasDropoffs" type="checkbox" [(ngModel)]="hasDropoffs" (ngModelChange)="filter()">
              <label for="hasDropoffs">
                {{ 'HasDropoffs' | translate }}
              </label>
            </div>
          </div>
        </div>

      </div>

      <div class="hidden xl:block 2xl:hidden"></div>

      <div class="hidden 2xl:block"></div>

    </div>

    <div class="grid md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-2 md:gap-4"
        [appHighlight]="filterWord">

      <ng-template ngFor [ngForOf]="filteredAccessPoints" let-accessPoint>
        <app-access-point-card
            [item]="accessPoint"
            [isQueued]="queuedIds.includes(accessPoint.id)"
            [filterWord]="searchTrackingNumbers ? filterWord : null">
        </app-access-point-card>
      </ng-template>

    </div>

  </main>

  <ng-template #loading>
    <app-loading-indicator></app-loading-indicator>
  </ng-template>

  <ng-container *ngIf="error">
    {{ 'AccessPointsResponseError' | translate }}
  </ng-container>

</div>

<ngx-smart-modal #pickupTrackingNumbersModal identifier="pickupTrackingNumbersModal" class="modal-lg">
  <h2 class="font-medium">
    <fa-icon icon="arrow-up"></fa-icon>
    {{ 'PickupTrackingNumbers' | translate }}
  </h2>
  <app-tracking-number-modal class="block my-4" *ngIf="pickupTrackingNumbersModal.hasData()"
      [item]="pickupTrackingNumbersModal.getData()"
      packagesProperty="packagesToPickup"></app-tracking-number-modal>
  <button class="branded" (click)="pickupTrackingNumbersModal.close()">
    {{ 'Close' | translate }}
  </button>
</ngx-smart-modal>

<ngx-smart-modal #dropoffTrackingNumbersModal identifier="dropoffTrackingNumbersModal" class="modal-lg">
  <h2 class="font-medium">
    <fa-icon icon="arrow-down"></fa-icon>
    {{ 'DropoffTrackingNumbers' | translate }}
  </h2>
  <app-tracking-number-modal class="block my-4" *ngIf="dropoffTrackingNumbersModal.hasData()"
      [item]="dropoffTrackingNumbersModal.getData()"
      packagesProperty="packagesToDropoff"></app-tracking-number-modal>
  <button class="branded" (click)="dropoffTrackingNumbersModal.close()">
    {{ 'Close' | translate }}
  </button>
</ngx-smart-modal>

<ngx-smart-modal #accessPointHistoryModal identifier="accessPointHistoryModal">
  <h2 class="font-medium">
    {{ 'History' | translate }}
  </h2>
  <app-access-point-history-modal class="block my-4" *ngIf="accessPointHistoryModal.hasData()"
      [item]="accessPointHistoryModal.getData()"></app-access-point-history-modal>
  <button class="branded" (click)="accessPointHistoryModal.close()">
    {{ 'Close' | translate }}
  </button>
</ngx-smart-modal>
