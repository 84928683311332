import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-map-link',
  templateUrl: './map-link.component.html',
  styleUrls: ['./map-link.component.scss']
})
export class MapLinkComponent implements OnInit {

  @Input() lat: number|null = 0;
  @Input() lon: number|null = 0;

  link = '';

  ngOnInit(): void {
    if (this.lat && this.lon) {
      this.link = `https://google.com/maps/?q=${this.lat},${this.lon}`;
    }
  }

}
