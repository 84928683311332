<div class="space-y-4">

  <div class="text-center break-all">
    {{ item.name }}
  </div>

  <ng-container *ngIf="item.history?.length; else noItems">
    <div *ngFor="let historyItem of item.history">

      <div class="mb-2 text-gray-600">
        {{ historyItem.created | date:'d MMMM y' }}
      </div>

      <ul class="space-y-1">

        <ng-template ngFor [ngForOf]="historyItem.pickups" let-trackingNumber>
          <li class="sm:flex items-center justify-between px-2 py-1 rounded bg-gray-100">
            <div class="text-sm font-medium uppercase">
              <fa-icon icon="arrow-up"></fa-icon>
              {{ 'Pickup' | translate }}
            </div>
            <div class="flex justify-between sm:inline">
              <app-tracking-number-link class="font-mono" [value]="trackingNumber"></app-tracking-number-link>
              <button class="branded-alt button-sm" data-tippy (click)="copyToClipboard(trackingNumber)">
                <fa-icon icon="copy"></fa-icon>
              </button>
            </div>
          </li>
        </ng-template>

        <ng-template ngFor [ngForOf]="historyItem.dropoffs" let-trackingNumber>
          <li class="sm:flex items-center justify-between px-2 py-1 rounded bg-gray-100">
            <div class="text-sm font-medium uppercase">
              <fa-icon icon="arrow-down"></fa-icon>
              {{ 'Dropoff' | translate }}
            </div>
            <div class="flex justify-between sm:inline">
              <app-tracking-number-link class="font-mono" [value]="trackingNumber"></app-tracking-number-link>
              <button class="branded-alt button-sm" data-tippy (click)="copyToClipboard(trackingNumber)">
                <fa-icon icon="copy"></fa-icon>
              </button>
            </div>
          </li>
        </ng-template>

      </ul>

    </div>
  </ng-container>

  <ng-template #noItems>
    <p>{{ 'NoItems' | translate }}</p>
  </ng-template>

</div>
