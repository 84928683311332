import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  faArrowDown,
  faArrowUp,
  faCopy,
  faHistory,
  faMapLocation,
  faPlus,
  faMinus,
  faRightFromBracket,
  faRotate,
  faSpinner,
  faXmark
} from '@fortawesome/free-solid-svg-icons';
import {
  faUps
} from '@fortawesome/free-brands-svg-icons';

@NgModule({
  imports: [
    FontAwesomeModule
  ],
  exports: [
    FontAwesomeModule
  ]
})
export class AppIconsModule {
  constructor(private faIconlibrary: FaIconLibrary) {
    this.faIconlibrary.addIcons(
      faArrowDown,
      faArrowUp,
      faCopy,
      faHistory,
      faMapLocation,
      faPlus,
      faMinus,
      faRightFromBracket,
      faRotate,
      faSpinner,
      faXmark,
      faUps
    );
  }
}
