export default {
  'AccessPointsResponseError': 'Failed to load the access points. Refresh the page to try again.',
  'FilterPlaceholder': 'Search for Address or Tracking Number',
  'AddressFilterPlaceholder': 'Search for Address',
  'BackToLogin': 'Back to login',
  'DriverNumberFor': 'Driver Access Code for',
  'DriverNumberResponseError': 'Failed to load the driver access code. Refresh the page to try again.',
  'Dropoffs': 'Dropoffs',
  'SearchTrackingNumbers': 'Search in tracking numbers',
  'HasDropoffs': 'Has dropoffs',
  'HasPickups': 'Has pickups',
  'Loading': 'Loading',
  'LoginFailedError': 'Something went wrong',
  'MapLink': 'Show on map',
  'Pickups': 'Pickups',
  'Results': '{count, plural, =0{No Results} =1{# Result} other{# Results}}',
  'PickupTrackingNumbers': 'Pickup Tracking Numbers',
  'DropoffTrackingNumbers': 'Dropoff Tracking Numbers',
  'NoItems': 'No items',
  'Free': 'Free'
};
