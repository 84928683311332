import { NgModule } from '@angular/core';
import { TranslateCompiler, TranslateModule } from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { TranslateService } from '@ngx-translate/core';

import en from '../locales/en';

@NgModule({
  imports: [
    TranslateModule.forRoot({
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      }
    })
  ],
  exports: [
    TranslateModule
  ]
})
export class AppTranslateModule {
  constructor(private translateService: TranslateService) {
    this.translateService.setTranslation('en', en);
    this.translateService.setDefaultLang('en');
    this.translateService.use('en');
  }
}
