import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@env';
import { makeHttpParams } from './utils';
import { AccessPoint, AccessPointHistory } from '@models/access-point';

@Injectable({
  providedIn: 'root'
})
export class AccessPointService {

  private baseUrl = `${environment.apiUrl}/v2/Dispatcher/accesspoints/`;

  constructor(private httpClient: HttpClient) { }

  getList(params?: any): Observable<AccessPoint[]> {
    params = makeHttpParams(params);
    return this.httpClient.get<AccessPoint[]>(this.baseUrl, { params });
  }

  getHistory(id: string, params?: any): Observable<AccessPointHistory> {
    params = makeHttpParams(params);
    return this.httpClient.get<AccessPointHistory>(`${this.baseUrl}/${id}/history`, { params });
  }

}
