import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  MsalGuard,
  MsalBroadcastService,
  MsalModule,
  MsalService,
  MsalInterceptor,
  MsalRedirectComponent
} from '@azure/msal-angular';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { NgxBarcode6Module } from 'ngx-barcode6';

import { AppComponent } from './app.component';

import { HighlightDirective } from './directives/highlight.directive';

import { AccessPointCardComponent } from './components/access-point-card/access-point-card.component';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { LoaderButtonComponent } from './components/loader-button/loader-button.component';
import { MapLinkComponent } from './components/map-link/map-link.component';
import { AccessPointHistoryModalComponent } from './components/access-point-history-modal/access-point-history-modal.component';
import { TrackingNumberModalComponent } from './components/tracking-number-modal/tracking-number-modal.component';
import { QueueComponent } from './components/queue/queue.component';
import { TrackingNumberLinkComponent } from './components/tracking-number-link/tracking-number-link.component';

import { AccessPointsComponent } from './views/access-points/access-points.component';
import { AccountComponent } from './views/account/account.component';
import { SigninOidcComponent } from './views/account/signin-oidc/signin-oidc.component';
import { LoginFailedComponent } from './views/account/login-failed/login-failed.component';

import { AppIconsModule } from './app-icons.module';
import { AppRoutingModule } from './app-routing.module';
import { AppTranslateModule } from './app-translate.module';

import { AUTH_CONFIG } from './configuration/auth-config';

@NgModule({
  declarations: [
    AppComponent,
    // Directives
    HighlightDirective,
    // Components
    AccessPointCardComponent,
    LoadingIndicatorComponent,
    LoaderButtonComponent,
    MapLinkComponent,
    AccessPointHistoryModalComponent,
    TrackingNumberModalComponent,
    QueueComponent,
    TrackingNumberLinkComponent,
    // Views
    AccessPointsComponent,
    AccountComponent,
    SigninOidcComponent,
    LoginFailedComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    NgxSmartModalModule.forRoot(),
    NgxBarcode6Module,
    AppIconsModule,
    AppRoutingModule,
    AppTranslateModule,
    MsalModule.forRoot(
      AUTH_CONFIG.msalInstance,
      AUTH_CONFIG.guardConfig,
      AUTH_CONFIG.interceptorConfig
    )
  ],
  providers: [
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {
			provide: HTTP_INTERCEPTORS,
			useClass: MsalInterceptor,
			multi: true
		}
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
