import { Component, Input, AfterViewInit } from '@angular/core';
import tippy from 'tippy.js';

import { AccessPoint } from '@models/access-point';
import { copyToClipboard } from '@utils';

@Component({
  selector: 'app-access-point-history-modal',
  templateUrl: './access-point-history-modal.component.html',
  styleUrls: ['./access-point-history-modal.component.scss']
})
export class AccessPointHistoryModalComponent implements AfterViewInit {

  copyToClipboard = copyToClipboard;

  @Input() item!: AccessPoint;

  ngAfterViewInit(): void {
    tippy('[data-tippy]', {
      trigger: 'click',
      content: 'Copied!',
      onShow(instance) {
        setTimeout(() => {
          instance.hide();
        }, 1000);
      }
    });
  }

}
