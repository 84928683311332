<ng-container *ngIf="items.length">

  <div class="grid md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-2 p-2 border-2 border-gray-300 rounded">

    <div class="card flex justify-between gap-x-2" *ngFor="let item of items">
      <div class="p-1">
        <div class="text-sm text-gray-500 font-mono">{{ item.id }}</div>
        <div class="leading-tight break-all">{{ item.name }}</div>
      </div>
      <button class="px-2 rounded bg-hover" (click)="queueService.remove(item)">
        <fa-icon icon="xmark"></fa-icon>
      </button>
    </div>

  </div>

  <div class="flex justify-end gap-2 mt-2">

    <button class="branded-alt" (click)="queueService.clear()">
      Clear
    </button>

    <app-loader-button classes="branded" [loading]="isDownloadingPdf" (action)="downloadPdf()">
      Download PDF
    </app-loader-button>

  </div>

</ng-container>
