import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { map } from 'lodash-es';
import { saveAs } from 'file-saver';

import { environment } from '@env';
import { makeHttpParams } from './utils';
import { AccessPoint } from '@models/access-point';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  private baseUrl = `${environment.apiUrl}/v2/Dispatcher/export`;

  constructor(private httpClient: HttpClient) { }

  getXls(): Observable<Blob> {
    const responseType = 'blob';
    return this.httpClient.get(`${this.baseUrl}/excel`, { responseType });
  }

  saveXls(saveAsName: string): Observable<Blob> {
    return this.getXls().pipe(tap((blob: Blob) => saveAs(blob, saveAsName)));
  }

  getPdf(items: AccessPoint[]): Observable<Blob> {
    const responseType = 'blob';
    const params = makeHttpParams({ accessPointIds: map(items, 'id') });
    return this.httpClient.get(`${this.baseUrl}/pdf`, { responseType, params });
  }

  savePdf(items: AccessPoint[], saveAsName: string): Observable<Blob> {
    return this.getPdf(items).pipe(tap((blob: Blob) => saveAs(blob, saveAsName)));
  }

}
