import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-loader-button',
  templateUrl: './loader-button.component.html',
  styleUrls: ['./loader-button.component.scss']
})
export class LoaderButtonComponent {

  @Input() loading = false;
  @Input() disabled = false;
  @Input() classes = '';

  @Output() action = new EventEmitter<MouseEvent>();

  emit(event: MouseEvent) {
    this.action.emit(event);
  }

}
