import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@env';
import { makeHttpParams } from './utils';

@Injectable({
  providedIn: 'root'
})
export class DriverNumberService {

  private baseUrl = `${environment.apiUrl}/v1/Dispatcher/drivernumber/`;

  constructor(private httpClient: HttpClient) { }

  get(params?: any): Observable<string> {
    params = makeHttpParams(params);
    return this.httpClient.get<string>(this.baseUrl, { params });
  }

}
