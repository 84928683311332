import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { environment } from '@env';

const b2cPolicies = {
  names: {
    signUpSignIn: 'B2C_1A_SIGNUP_SIGNIN_GROUPS'
  },
  authorities: {
    signUpSignIn: {
      authority: `https://${environment.b2cTenant}.b2clogin.com/${environment.b2cTenant}.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN_GROUPS`
    }
  },
  authorityDomain: `${environment.b2cTenant}.b2clogin.com`
}

const clientApplication = new PublicClientApplication({
  auth: {
    clientId: environment.b2cClientId,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    redirectUri: `${environment.selfUrl}/account/signin-oidc`,
    knownAuthorities: [
      b2cPolicies.authorityDomain
    ]
  },
  cache: {
    cacheLocation: 'localStorage'
  }
});

const guardConfig: MsalGuardConfiguration = {
  interactionType: InteractionType.Redirect,
  authRequest: {
    scopes: [
      'user.read',
      'openid',
      'profile',
      `https://${environment.b2cTenant}.onmicrosoft.com/${environment.b2cApiClientId}/ups_access`
    ]
  },
  loginFailedRoute: '/account/login-failed'
}

export function MsalInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>([
    ['https://graph.microsoft.com/v1.0/me', ['user.read', 'profile']],
    [environment.apiUrl, [
      `https://${environment.b2cTenant}.onmicrosoft.com/${environment.b2cApiClientId}/ups_access`
    ]]
  ]);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export const AUTH_CONFIG: {
  msalInstance: PublicClientApplication,
  guardConfig: MsalGuardConfiguration,
  interceptorConfig: MsalInterceptorConfiguration
} = {
  msalInstance: clientApplication,
  guardConfig: guardConfig,
  interceptorConfig: MsalInterceptorConfigFactory()
}
