import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { unionBy, reject } from 'lodash-es';

import { AccessPoint } from '@models/access-point';
import { toLocalStorage, fromLocalStorage } from '@utils';

@Injectable({
  providedIn: 'root'
})
export class QueueService {

  public readonly items$: BehaviorSubject<AccessPoint[]> = new BehaviorSubject<AccessPoint[]>([]);

  constructor() {
    this.items$.next(fromLocalStorage('queue') ?? [] as AccessPoint[]);
  }

  private persist(): void {
    toLocalStorage('queue', this.items$.value);
  }

  add(accessPoint: AccessPoint): void {
    this.items$.next(unionBy(this.items$.value, [accessPoint], 'id'));
    this.persist();
  }

  remove(accessPoint: AccessPoint): void {
    this.items$.next(reject(this.items$.value, { id: accessPoint.id }));
    this.persist();
  }

  clear(): void {
    this.items$.next([]);
    this.persist();
  }

}
