import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tracking-number-link',
  templateUrl: './tracking-number-link.component.html',
  styleUrls: ['./tracking-number-link.component.scss']
})
export class TrackingNumberLinkComponent {

  @Input() value!: string;

  trackingBaseUrl = 'https://wwwapps.ups.com/tracking/tracking.cgi?tracknum=';

}
