import { Directive, Input, AfterViewChecked, ElementRef } from '@angular/core';
import * as Mark from 'mark.js';

@Directive({
  selector: '[appHighlight]'
})
export class HighlightDirective implements AfterViewChecked {

  @Input('appHighlight') value: string = '';

  constructor(private elementRef: ElementRef) { }

  ngAfterViewChecked() {
    const marker = new Mark(this.elementRef.nativeElement.querySelectorAll('.highlight-item'));
    marker.unmark({ done: () => {
      if (this.value) {
        marker.mark(this.value, { separateWordSearch: false });
      }
    }});
  }

}
