import { Component, OnDestroy, OnInit } from '@angular/core';
import { catchError, forkJoin, of, Subject, Subscription, takeUntil } from 'rxjs';
import { cloneDeep, deburr, filter, orderBy, pick, find, map } from 'lodash-es';
import * as countryList from 'country-list-js';

import { AccessPoint, AccessPointPackage } from '@models/access-point';
import { AccessPointService } from '@services/access-point.service';
import { DriverNumberService } from '@services/driver-number.service';
import { QueueService } from '@services/queue.service';
import { DownloadService } from '@services/download.service';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-access-points',
  templateUrl: './access-points.component.html',
  styleUrls: ['./access-points.component.scss']
})
export class AccessPointsComponent implements OnInit, OnDestroy {

  ready = false;
  error = false;

  isLoading = false;
  isDownloadingXls = false;

  date = new Date();

  driverNumber = '';
  accessPoints: AccessPoint[] = [];

  filterWord = '';
  filteredAccessPoints: AccessPoint[] = [];

  searchTrackingNumbers = true;
  hasDropoffs = false;
  hasPickups = false;

  private queueSubscription!: Subscription;
  queuedIds: string[] = [];

  private readonly _destroy$ = new Subject<boolean>();

  constructor(
    private accessPointService: AccessPointService,
    private driverNumberService: DriverNumberService,
    public queueService: QueueService,
    private downloadService: DownloadService,
    public authService: AuthService
  ) { }

  ngOnInit(): void {

    this.queueSubscription = this.queueService.items$
        .subscribe((items: AccessPoint[]) => this.queuedIds = map(items, 'id'));

    this.load();

  }

  ngOnDestroy(): void {

    this.queueSubscription.unsubscribe();

    this._destroy$.next(true);
    this._destroy$.unsubscribe();

  }

  load(): void {

    this.isLoading = true;
    this.error = false;

    forkJoin({
      driverNumber: this.driverNumberService.get().pipe(catchError(() => of(''))),
      accessPoints: this.accessPointService.getList().pipe(catchError(() => of([])))
    })
    .pipe(takeUntil(this._destroy$))
    .subscribe(data => {
      this.accessPoints = data.accessPoints;
      this.driverNumber = data.driverNumber;
      this.substituteCountryCodes();
      this.filter();
      this.ready = true;
      this.isLoading = false;
    });

  }

  substituteCountryCodes() {
    for (const accessPoint of this.accessPoints) {
      let country;
      if (accessPoint.country) {
        // Queries are cached by library
        if (accessPoint.country.match(/^[a-z]{2}$/i)) {
          country = countryList.findByIso2(accessPoint.country);
        }
        if (accessPoint.country.match(/^[a-z]{3}$/i)) {
          country = countryList.findByIso3(accessPoint.country);
        }
        if (country) {
          accessPoint.country = country.name;
        }
      }
    }
  }

  filter(override?: string): void {
    if (override !== undefined) {
      this.filterWord = override;
    }
    const mustIncludeFilterWord = (accessPoint: AccessPoint): boolean => {
      const normalizedFilterWord = deburr(this.filterWord).toLowerCase();
      for (const key in pick(accessPoint, ['name', 'street', 'streetNumber', 'zipCode', 'city'])) {
        const normalizedValue = deburr(accessPoint[key as keyof AccessPoint] as string).toLowerCase();
        if (normalizedValue.includes(normalizedFilterWord)) {
          return true;
        }
      }
      const whereTrackingNumberIncludesFilterWord = (accessPointPackage: AccessPointPackage): boolean => {
        return accessPointPackage.trackingNumber.toLowerCase().includes(normalizedFilterWord);
      }
      if (this.searchTrackingNumbers &&
          (find(accessPoint.packagesToPickup, whereTrackingNumberIncludesFilterWord) ||
          find(accessPoint.packagesToDropoff, whereTrackingNumberIncludesFilterWord))) {
        return true;
      }
      return false;
    };
    this.filteredAccessPoints = this.accessPoints;
    if (this.hasDropoffs) {
      this.filteredAccessPoints = filter(
        this.filteredAccessPoints,
        accessPoint => accessPoint.packagesToDropoff.length > 0
      );
    }
    if (this.hasPickups) {
      this.filteredAccessPoints = filter(
        this.filteredAccessPoints,
        accessPoint => accessPoint.packagesToPickup.length > 0
      );
    }
    if (this.filterWord) {
      this.filteredAccessPoints = filter(this.filteredAccessPoints, mustIncludeFilterWord);
    }
    this.filteredAccessPoints = orderBy(this.filteredAccessPoints, 'name');
    this.filteredAccessPoints = cloneDeep(this.filteredAccessPoints); // Force re-render
  }

  downloadXls() {
    this.isDownloadingXls = true;
    this.downloadService.saveXls('access-points.xlsx').subscribe(() => {
      this.isDownloadingXls = false;
    });
  }

}
