export const environment = {
  production: false,
  //apiUrl: 'https://localhost:44300/api',
  //selfUrl: 'http://localhost:4200',
  apiUrl: 'https://ups.adapter.staging.world-of-lockers.com/api',
  selfUrl: 'https://ups.staging.world-of-lockers.com',
  b2cTenant: 'wolb2cstaging',
  b2cClientId: 'e7bffa86-8683-4167-8c4e-398b7616e79e',
  b2cApiClientId: '95b2256a-f6e8-48c8-a1a2-564959ff3587'
};
