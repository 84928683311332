import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { QueueService } from '@services/queue.service';
import { DownloadService } from '@services/download.service';
import { AccessPoint } from '@models/access-point';

@Component({
  selector: 'app-queue',
  templateUrl: './queue.component.html',
  styleUrls: ['./queue.component.scss']
})
export class QueueComponent implements OnInit, OnDestroy {

  items: AccessPoint[] = [];

  isDownloadingPdf = false;

  private queueSubscription!: Subscription;

  constructor(
    public queueService: QueueService,
    private downloadService: DownloadService
  ) { }

  ngOnInit(): void {
    this.queueSubscription = this.queueService.items$
        .subscribe((items: AccessPoint[]) => this.items = items);
  }

  ngOnDestroy(): void {
    this.queueSubscription.unsubscribe();
  }

  downloadPdf(): void {
    this.isDownloadingPdf = true;
    this.downloadService.savePdf(this.queueService.items$.value, 'access-points.pdf').subscribe(() => {
      this.isDownloadingPdf = false;
    });
  }

}
