<article class="card h-full flex flex-col outline-2 outline-blue-500"
    [class.outline]="isQueued">

  <div class="flex justify-between px-1 text-sm text-gray-500">
    <div class="font-mono">
      {{ item.id }}
    </div>
    <app-map-link [lat]="item.latitude" [lon]="item.longitude"></app-map-link>
  </div>

  <div class="grow px-1 py-2 leading-tight break-all highlight-item">
    <div class="font-medium">
      {{ item.name }}
    </div>
    <div>
      {{ item.street }} {{ item.streetNumber }}
    </div>
    <div>
      {{ item.zipCode }} {{ item.city }}
    </div>
    <div *ngIf="item.country">
      {{ item.country }}
    </div>
  </div>

  <div class="flex flex-wrap gap-2 px-1 py-2 mb-2">
    <ng-container *ngIf="item.availableLockers.length">
      {{ 'Free' | translate }}:
    </ng-container>

    <div class="flex gap-2 p-[1px] pl-2 bg-gray-300 rounded" *ngFor="let locker of item.availableLockers">
      <div class="font-medium">{{ locker.type }}</div>
      <div class="px-2 bg-white rounded-r whitespace-nowrap">
        {{ locker.available }}
      </div>
    </div>
  </div>

  <div class="grid grid-cols-[1fr,1fr,auto,auto] gap-1">

    <button [disabled]="!item.packagesToPickup.length"
        class="flex items-center justify-between p-2 rounded bg-hover outline-2 outline-primaryLight"
        [class.outline]="highlightPickups"
        (click)="showPickupTrackingNumbersModal()">
      <div class="text-sm font-medium uppercase">
        <fa-icon icon="arrow-up"></fa-icon>
        {{ 'Pickups' | translate }}
      </div>
      <div class="font-mono">
        {{ item.packagesToPickup.length }}
      </div>
    </button>

    <button [disabled]="!item.packagesToDropoff.length"
        class="flex items-center justify-between p-2 rounded bg-hover outline-2 outline-primaryLight"
        [class.outline]="highlightDropoffs"
        (click)="showDropoffTrackingNumbersModal()">
      <div class="text-sm font-medium uppercase">
        <fa-icon icon="arrow-down"></fa-icon>
        {{ 'Dropoffs' | translate }}
      </div>
      <div class="font-mono">
        {{ item.packagesToDropoff.length }}
      </div>
    </button>

    <button class="p-2 rounded bg-hover" (click)="showHistory()">
      <ng-container *ngIf="historyLoading; else historyIcon">
        <fa-icon icon="spinner" [spin]="true" [fixedWidth]="true"></fa-icon>
      </ng-container>
      <ng-template #historyIcon>
        <fa-icon icon="history" [fixedWidth]="true"></fa-icon>
      </ng-template>
    </button>

    <ng-container *ngIf="isQueued; else queueAddButton">
      <button class="p-2 rounded bg-hover" (click)="queueService.remove(item)">
        <fa-icon icon="minus" [fixedWidth]="true"></fa-icon>
      </button>
    </ng-container>

    <ng-template #queueAddButton>
      <button class="p-2 rounded bg-hover" (click)="queueService.add(item)">
        <fa-icon icon="plus" [fixedWidth]="true"></fa-icon>
      </button>
    </ng-template>

  </div>

</article>
