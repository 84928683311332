export function toLocalStorage(key: string, item: any): void {
  localStorage.setItem(key, JSON.stringify(item));
}

export function fromLocalStorage(key: string): any {
  const item = localStorage.getItem(key);
  return item ? JSON.parse(item) : null;
}

export function copyToClipboard(text: string): void {
  navigator.clipboard.writeText(text);
}
