import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

import { AccessPointsComponent } from './views/access-points/access-points.component';
import { AccountComponent } from './views/account/account.component';
import { LoginFailedComponent } from './views/account/login-failed/login-failed.component';
import { SigninOidcComponent } from './views/account/signin-oidc/signin-oidc.component';

const routes: Routes = [
  { 
    path: '', 
    redirectTo: '/access-points', 
    pathMatch: 'full' 
  },
  { 
    path: 'access-points', 
    component: AccessPointsComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'account',
    component: AccountComponent,
    children: [
      {
        path: 'login-failed',
        component: LoginFailedComponent
      },
      {
        path: 'signin-oidc',
        component: SigninOidcComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
