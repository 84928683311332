import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventType, EventMessage, InteractionType } from '@azure/msal-browser';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';

import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private router: Router,
    private broadcastService: MsalBroadcastService,
    private authService: AuthService,
    private msalService: MsalService
  ) { }

  ngOnInit() {

    this.broadcastService.msalSubject$.subscribe((result: EventMessage) => {
      if (result.eventType === EventType.LOGIN_SUCCESS && result.interactionType === InteractionType.Redirect) {
        this.router.navigate(['/access-points']);
      }
    });

    this.authService.setAccount();

    if (!this.authService.getAccount()) {
      this.handleLogin();
    } 

	}

  handleLogin() {
    this.msalService.handleRedirectObservable().subscribe(data => {
      if (data) {
        this.router.navigate(['/access-points']);
      } else {
        this.msalService.instance.loginRedirect();
      }
    })
  }

}
