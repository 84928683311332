import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import tippy from 'tippy.js';

import { AccessPoint, AccessPointPackage } from '@models/access-point';
import { copyToClipboard } from '@utils';

@Component({
  selector: 'app-tracking-number-modal',
  templateUrl: './tracking-number-modal.component.html',
  styleUrls: ['./tracking-number-modal.component.scss']
})
export class TrackingNumberModalComponent implements OnInit, AfterViewInit {

  copyToClipboard = copyToClipboard;

  @Input() item!: AccessPoint;
  @Input() packagesProperty!: keyof AccessPoint;

  packages: AccessPointPackage[] = [];

  ngOnInit(): void {
    this.packages = this.item[this.packagesProperty] as AccessPointPackage[];
  }

  ngAfterViewInit(): void {
    tippy('[data-tippy]', {
      trigger: 'click',
      content: 'Copied!',
      onShow(instance) {
        setTimeout(() => {
          instance.hide();
        }, 800);
      }
    });
  }

}
